<template>
  <!-- Continue this when multiple images available in Sitecore -->
  <img :srcset="srcset" :alt="alt" />
</template>

<script>
export default {
  name: 'ImageSrcset',
  props: {
    source: {
      type: String,
      required: true
    },
    alt: {
      type: String,
      default: ''
    },
    sizes: {
      type: Object,
      default: () => ({
        sm: 640,
        md: 768,
        lg: 1024,
        xl: 1280
      })
    },
    ext: {
      type: String,
      default: 'jpg'
    }
  },
  computed: {
    srcset() {
      const sizes = this.sizes
      let set = ''
      Object.keys(sizes).forEach((size, i) => {
        set =
          set +
          `${this.source + '-' + size + '.' + this.ext + ' ' + sizes[size]}w,`
      })
      return set
    }
  }
}
</script>
